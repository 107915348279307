<template>
    <v-row style="height: 500px" align="center">
        <v-col class="text-center">
            <span class="text-h5 font-weight-light">Erro 404 | Página não encontrada</span>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name : "Erro"
}
</script>

<style scoped>

</style>